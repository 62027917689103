import * as React from 'react';
import '../../Homepage/style.scss';
import images from '../../../../images/homepage/index';
import StackhawkTeal from '../../../../images/solutions/modernDAST/stackhawk.svg';
import DifferenceImg from '../../../../images/solutions/modernDAST/difference.png';
import ScalabilityImg from '../../../../images/solutions/modernDAST/scalability.png';
import InteroperabilityImg from '../../../../images/solutions/modernDAST/interoperability.png';
import EfficiencyImg from '../../../../images/solutions/modernDAST/efficiency.png';
import IntegrationsImg from '../../../../images/solutions/modernDAST/integrations.svg';
import SmallerIntegrationsImg from '../../../../images/solutions/modernDAST/integrations-smaller.svg';
import TestImg from '../../../../images/solutions/modernDAST/test.png';
import * as solutionsStyles from '../style.module.scss';
import * as containerStyles from '../../Container/style.module.scss';
import Container from 'components/_POC/Container';
import HeroImg from '../../../../images/solutions/modernDAST/hero-img.svg';
import Button from 'components/_POC/Button';
import Table from 'components/_POC/Table';
import { DEMO_PAGE_URL, SIGNUP_URL, REQUEST_DEMO_ANCHOR } from 'core/links';
import { defaultButtonText } from 'components/RequestADemoModal/constants';

export const ModernDAST: React.FunctionComponent = () => {
  return (
    <div id="modernDAST" className={`mt-32 container ${solutionsStyles.modernDASTWrapper}`}>
      <div className="px-4">
        <div className={`flex flex-col justify-center items-center py-16 homepage-hero ${solutionsStyles.hero}`}>
          <div className={solutionsStyles.heroLeft}>
            <h1 className="text-5xl font-extrabold leading-15">Dynamic Application Security Testing</h1>
            <h4>Purpose-built for modern engineering teams</h4>
            <p>
              StackHawk is the only dynamic application security testing solution that was built to bridge the trust gap
              between AppSec and Developers to deliver more secure software faster.
              <span>
                {' '}
                Focused on runtime and pre-production application security testing, StackHawk gives teams the ability to
                actively run security testing as part of their CI/CD workflows.
              </span>
            </p>
            <div className={solutionsStyles.heroButtonContainer}>
              <Button
                type="button"
                variant="button-simple"
                label="Watch a Demo"
                style="btn-primary"
                disabled={false}
                size="header"
                url={DEMO_PAGE_URL}
              />
              <Button
                type="button"
                variant="button-simple"
                label="Create a Free Account"
                style="outline"
                disabled={false}
                size="header"
                url={SIGNUP_URL}
              />
            </div>
          </div>
          <div className={solutionsStyles.heroRight}>
            <img src={HeroImg} alt="Hero Image" />
          </div>
        </div>
        <Container
          icon={StackhawkTeal}
          image={DifferenceImg}
          button1Text="Learn More"
          button1Link="/blog/dynamic-application-security-testing-overview/"
          variant="wide5050"
          heading="The StackHawk Difference"
          description="StackHawk’s deliberate approach to developer focused application security testing helps organizations improve their security posture by eliminating operational inefficiencies, accelerating security-tested releases, and managing risk appropriately."
          cssClass="difference"
        />
        <div className={`${solutionsStyles.textSection} text-center`}>
          <h2>
            <span className="text-primary">Shift Security Left </span>
            <br /> with Automated DAST Scanning
          </h2>
          <p>
            Scheduled application security scans of production environments no longer cut it. DevSecOps requires DAST
            scanning that is automated in the CI/CD pipeline. StackHawk is purpose-built to run in the DevOps pipeline,
            ensuring your team has eyes on any new vulnerabilities before they hit production.
          </p>
        </div>
        <div className={`${containerStyles.alteredGrid} ${solutionsStyles.alteredGrid}`}>
          <Container
            image={ScalabilityImg}
            variant="twoThirds"
            heading="Scalability"
            description="Scale AppSec with automation and existing Developer resources"
            cssClass="solutionsTwoRows"
          />
          <Container
            variant="oneThird"
            heading="Run in ANY CI/CD"
            description="Run in CI/CD where existing software development takes place"
            cssClass="noPMargin"
          />
          <Container
            heading="Find, Triage and FIX"
            description="Proactively find, triage, and fix bugs before production with automated API security testing"
            variant="oneThird"
            cssClass="noPMargin"
          />
          <Container
            heading="Dev-First AppSec"
            description="Built for engineers to own the initial triage and fix security issues"
            variant="oneThird"
            cssClass="noPMargin"
          />
        </div>
        <div className={`${solutionsStyles.textSection} text-center`}>
          <h2>
            <span className="text-primary">Reliably Test </span> <br />
            Applications and APIs
          </h2>
          <p>
            Application architecture has advanced over the past decade, requiring application security testing that is
            built for scanning microservices, APIs, traditional, and single-page applications. With StackHawk, you can
            align your DAST testing with your architecture for better performance and faster fixes.
          </p>
        </div>
        <Container
          image={TestImg}
          variant="wide"
          heading="Test ALL APIs"
          description="Exhaustively test REST, SOAP, GraphQL, and gRPC APIs"
          cssClass="testAPIs"
        />
        <div className={`${containerStyles.alteredGrid} ${solutionsStyles.alteredGrid} ${containerStyles.apiTesting}`}>
          <Container
            variant="twoThirds"
            heading="Interoperability"
            description="Run scans in parallel with existing build tools for increased performance"
            cssClass="interoperability"
            image={InteroperabilityImg}
          />
          <Container
            heading="Accuracy"
            description="Utilize your existing test data to match your endpoints"
            variant="oneThird"
            cssClass="noPMargin"
          />
          <Container
            heading="Customizable"
            description="Create custom test scripts to cover specific scenarios for your application"
            variant="oneThird"
            cssClass="customizable"
          />
          <Container
            heading="Ship Safer Code"
            description="Safeguard applications with depth of scan and API testing as part of software testing best practices"
            variant="twoThirds"
            cssClass="saferCode"
          />
        </div>
        <div className={`${solutionsStyles.textSection} text-center`}>
          <h2>
            Happy Engineers, <br /> <span className="text-primary">Scaled AppSec Teams</span>
          </h2>
          <p>
            Legacy DAST solutions focus on giving Security teams the tools to test for vulnerabilities in production,
            which introduces disruptions to Developer workflows and delays shipping code. With StackHawk’s modern
            approach to DAST, Developers can write secure software faster, and Security teams can scale at the speed of
            software being deployed.
          </p>
        </div>
        <div
          id={solutionsStyles.happyEngineers}
          className={`${containerStyles.grid} ${solutionsStyles.grid} ${solutionsStyles.halfGridReverse}`}
        >
          <Container
            variant="oneThirdReverse"
            heading="Build, Test AND Scale"
            description="Build security testing into software best practices and lean on developer expertise to scale security testing workflows"
            cssClass="noPMargin"
          />
          <Container
            heading="Automation"
            description="Automate application and API testing within CI/CD workflows"
            variant="oneThirdReverse"
            cssClass="noPMargin"
          />
          <Container
            heading="Trust and Verify"
            description="Trust and verify for faster fixes"
            variant="oneThirdReverse"
            cssClass="noPMargin"
          />
          <Container
            heading="Efficiency"
            description="Seamlessly run scans every time code is checked&nbsp;in"
            variant="twoThirds"
            cssClass="twoRowsReverse"
            image={EfficiencyImg}
          />
        </div>
        <div className={`${solutionsStyles.textSection} text-center ${solutionsStyles.textSectionWithButton}`}>
          <h2>
            Built for the <br /> <span className="text-primary">Modern Engineering Stack</span>
          </h2>
          <p>
            Rolling out DevSecOps within an organization requires security tools that fit into existing engineering
            workflows. From scan kickoffs to finding alerts to backlog prioritization, your DAST tooling should tie in
            with your engineering stack. StackHawk was built for teams that deploy software every day.
          </p>
          <a href="/integrations/">
            <button className="text-primary getStartedButton hoverWiggle">
              Learn about Integrations{' '}
              <em>
                <img src={images.ArrowRightIcon} alt="Right Arrow Icon" />
              </em>
            </button>
          </a>
        </div>
        <Container
          image={IntegrationsImg}
          smallerImg={SmallerIntegrationsImg}
          variant="wide"
          heading="Integrates into Your Workflow"
          button1Link="https://docs.stackhawk.com/workflow-integrations/"
          button1Text="Read the Docs"
          description="Automate with CI/CD pipeline integrations"
          cssClass="integratesWorkflow"
        />
        <div className={`${solutionsStyles.grid} ${solutionsStyles.halfGrid}`}>
          <Container
            heading="Management"
            description="Manage findings in existing ticketing systems and application security testing on every PR"
            variant="oneThird"
            cssClass="halfLeft"
          />
          <Container
            heading="Flexiblity"
            description="Runs anywhere, on any platform and is language agnostic"
            variant="oneThird"
            cssClass="halfRight"
          />
        </div>
        <div className={solutionsStyles.dastStackUp}>
          <div className={`${solutionsStyles.textSection} text-center ${solutionsStyles.textSectionWithButton}`}>
            <h2>How Does Your DAST Stack Up?</h2>
            <p>
              Whether you are implementing dynamic application security testing for the first time or are evaluating
              against existing systems, make sure you are using modern DAST tooling.
            </p>
            <a href="/product/">
              <button className="text-primary getStartedButton hoverWiggle">
                Learn More About Our Product{' '}
                <em>
                  <img src={images.ArrowRightIcon} alt="Right Arrow Icon" />
                </em>
              </button>
            </a>
          </div>
          <Table />
        </div>
        <div className="mb-6">
          <Container
            image={images.StackHawkInFlight}
            button1Text={defaultButtonText}
            button1Link={REQUEST_DEMO_ANCHOR}
            variant="wide5050"
            heading="Want to know how StackHawk can improve your API Security and AppSec Programs?"
            description="Schedule time with our experts for a live demo."
            cssClass="mobileHideImg"
          />
        </div>
        <div className="mb-10">
          <Container
            heading="Get Hands-On Experience <br/> <span>Give Us A Test Drive!</span>"
            description="We know you might want to test drive a full version of security software before you talk to us. So, Get It On!"
            button1Text="Start Your Free Trial"
            button1Link="https://auth.stackhawk.com/signup"
          />
        </div>
      </div>
    </div>
  );
};
