// extracted by mini-css-extract-plugin
export var alteredGrid = "style-module--alteredGrid--1JMgx";
export var dastStackUp = "style-module--dastStackUp--KPwmN";
export var grid = "style-module--grid--4IwbX";
export var gridTwoThirds = "style-module--gridTwoThirds--O1Af0";
export var halfGrid = "style-module--halfGrid---OSI3";
export var halfGridReverse = "style-module--halfGridReverse--nfLkF";
export var happyEngineers = "style-module--happyEngineers--fxID8";
export var hero = "style-module--hero--oTkBd";
export var heroButtonContainer = "style-module--heroButtonContainer--0+yX4";
export var heroLeft = "style-module--heroLeft--b51Eq";
export var heroRight = "style-module--heroRight--eefah";
export var modernDASTWrapper = "style-module--modernDASTWrapper--Uwuv0";
export var textSection = "style-module--textSection--e3-CQ";
export var textSectionWithButton = "style-module--textSectionWithButton--BynrL";